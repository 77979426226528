<template>
  <div id="mian">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>电子账簿名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="电子账簿名称/编号"
          ></el-input>
        </div>
        <div class="item">
          <i>所属商户</i>
          <el-select v-model="query.mchId">
              <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="(v, i) in mchDrop"
                  :key="i"
                  :label="v.mchName"
                  :value="v.mchId"
                >
                </el-option>
          </el-select>
        </div>
        <div class="item">
          <i>电子账簿账号</i>
          <el-input
            v-model="query.accountNo"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="电子账簿账号"
          ></el-input>
        </div>      
      </div>
      <div class="lineP">
        <div class="itembtn">
          <el-button type="primary" class="searchBt" @click="getLists">查询</el-button>     
        </div>   
      </div>
    </div>   
    <div class="tab1">
      <el-table :data="tabData"
          style="width: 100%"
          :row-style="{height: '60px'}"
          :header-cell-style="{
            color: '#606266',
            width: '100%',
            height:'60px',
            background: '#F4F5F6',
          }"
        >
          <el-table-column  min-width="200" align="center">
              <template slot="header">
                <p>电子账簿名称</p>
                <p>电子账簿编号</p>
              </template>
              <template slot-scope="{ row }">
                <p>{{ row.allocMchName }}</p>
                <p>{{ row.allocMchId }}</p>
              </template>
          </el-table-column>
          <el-table-column prop="accountNo" label="电子账簿账号" align="center"  min-width="250"/>
          <el-table-column prop="mchName" label="所属商户" align="center"  min-width="250"/>
          <el-table-column label="可结算余额（元）" align="center" min-width="140">
            <template slot-scope="{ row }" v-if="row.balance != undefined || row.balance == ''">
              {{ (row.balance.availableAmount / 100) | formatMoney }}
            </template>
          </el-table-column>
          <el-table-column label="冻结余额（元）" align="center" min-width="140">
            <template slot-scope="{ row }"  v-if="row.balance != undefined || row.balance == ''">
              {{ (row.balance.frozenAmount / 100) | formatMoney }}
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center"  min-width="100">
              <template slot-scope="{ row }">
                 <!-- channelStatus: "FROZEN", // ACTIVATED（激活）、FROZEN（冻结）  -->
                <p v-show="OFFON_InfoStatus != 'ELECTRON:BOOK:INFO/STATUS'"> {{ row.channelStatus == "ACTIVATED" ? "已开启" : "已关闭" }}</p> 
                <p v-auth="'ELECTRON:BOOK:INFO/STATUS'">
                  <el-switch
                    v-model="row.channelStatus"
                    @change="setStatus(row)"
                    active-color="#13ce66"
                    active-value="ACTIVATED"
                    inactive-value="FROZEN"
                  >
                  </el-switch>
                </p>
              </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
          >
            <template slot-scope="scope"> 
              <p><a class="lianjie" @click="SearBtn(scope.$index,scope.row)">查询</a></p>
            </template>
          </el-table-column>
      </el-table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getAccountBookDetail,getAccountBookDeSear , setStatus} from "@/api/trading/electron.js";
import { merchantsDrop  } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        mchId: "",
        keyword: "",
        accountNo:"",
      },
      totalNum: 0,
      tabData: [],
      mchDrop: [],//所属商户
      OFFON_InfoStatus:'',
    };
  },
  computed: {
    ...mapState({
      electronDetail: "electron_infolist",
    }),
  },
  created() {
    if (this.electronDetail) {
      this.query = this.electronDetail.query;
    }
    this.getDrop();
    this.getLists(false);
    this.listJurisdiction();
  },
  methods: {
    ...mapMutations({
      setElectronInfo: "electron_setInfolist",
    }),
    //获取列表
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.keyword = this.query.keyword.trim();
      getAccountBookDetail(this.query).then((res) => {
        if (res) {
          this.tabData = res.resultData.mchTradeChannel;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    //列表查询
    SearBtn(index,item){
      console.log(index ,item)
      getAccountBookDeSear(item.mchChannelId).then((res) => {
        if(res){
          // var params = {...item,...res.resultData};
          // console.log(params)     
          let balance = res.resultData.balance
          this.$set(
              this.tabData[index],
              "balance",
              balance
          ); 
          this.$message.success({message:"查询成功"});
        }
      })
    },
    //启用、关闭机构
    setStatus(value) {
      console.log(value);
      this.$confirm("此操作将修改状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
          let data = {
            mchChannelId: value.mchChannelId,
            channelStatus: value.channelStatus,
          };
          console.log(data,"put")
          setStatus(data).then((res) => {
            if (!res) {
              value.channelStatus = value.channelStatus ? 'FROZEN' : 'ACTIVATED';
            }
          });
      }).catch(() => {
        value.channelStatus = value.channelStatus == "ACTIVATED" ? "FROZEN" : "ACTIVATED";
          this.$message({
            type: "info",
            message: "已取消操作",
          });
      });
    }, 
    // 获取下拉
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    //判断列表按钮是否有权限
		listJurisdiction(){
					const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
           if(_resources=='ALL'){
                this.OFFON_InfoStatus = 'ELECTRON:BOOK:INFO/STATUS';
           }else{
              if(_resources.split(';').indexOf('ELECTRON:BOOK:INFO/STATUS') !== -1 ){
                this.OFFON_InfoStatus = 'ELECTRON:BOOK:INFO/STATUS';
              }else{
                this.OFFON_InfoStatus = "";
              }
           }
		},         
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setElectronInfo(data);
  },
};
</script>
<style scoped>
.con_from .lineP .item{
  width: 29%;
  margin-left: 6.5%;
}
.con_from .lineP .item:first-child{
  margin-left: 0%;
}
.con_from .lineP .item i {
  width: 92px;
}
.con_from .lineP .itemTime{
  width: 64.55%;
}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 92px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 130px - 92px - 44.67px - 10px) / 2);
}
.con_from .lineP .item .inputW {
  width: calc((100% - 92px - 24.67px - 10px) / 2);
}
.con_from .lineP .item .inputW ::v-deep .el-input__inner{
  padding: 0px 0px 0px 15px !important;
}
/deep/ .el-table th>.cell{    font-weight: normal;}
/deep/ .el-table tbody tr:hover > td {
    background-color: #fff !important;
}
/deep/ .el-table th, .el-table tr {
    border-top: 1px solid rgba(220, 224, 230, 1) !important;
    border-bottom: 1px solid rgba(220, 224, 230, 1) !important;
}
/deep/ .el-table td, .el-table th.is-leaf {
   border-bottom: 1px solid rgba(220, 224, 230, 1) !important;
}
/deep/ .el-table th>.cell , /deep/ .el-table th>.cell p ,  /deep/ .el-table p , /deep/ .el-table{    font-weight: normal;    font-size: 14px;
    font-family: Microsoft YaHei;line-height: 19px;
    color: rgba(102, 102, 102, 1);}
/deep/ .el-table tbody tr:hover > td {
    background-color: #fff !important;
}
</style>
